<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col v-if="productLog.content" cols="12" sm="4">
        <last-log :colorCard="productLog.color"
        :title="productLog.title"
        :content="productLog.content"
        :destinyShop="productLog.destinyShop"
        :date="productLog.date"
        :type="productLog.type" />
      </v-col>
      <v-col v-if="inventoryLog.content" cols="12" sm="4">
        <last-log :colorCard="inventoryLog.color"
        :title="inventoryLog.title"
        :content="inventoryLog.content ? inventoryLog.content : 'NOT FOUND'"
        :destinyShop="inventoryLog.destinyShop"
        :date="inventoryLog.date"
        :type="inventoryLog.type" />
      </v-col>
      <v-col v-if="orderLog.content" cols="12" sm="4">
        <last-log :colorCard="orderLog.color"
        :title="orderLog.title"
        :content="orderLog.content"
        :destinyShop="orderLog.destinyShop"
        :date="orderLog.date"
        :type="orderLog.type" />
      </v-col>
    </v-row>
   <v-card outlined
          tile
          color="white">
    <v-row max-height="400" no-gutters>
        <v-col cols="12" sm="6">
          <chart-bar id="today"
            v-if="loadedToday"
            :chartdata="chartdataToday"
            :options="optionsToday"/>
        </v-col>
        <v-col cols="12" sm="6">
          <chart-bar id="month"
            v-if="loadedMonth"
            :chartdata="chartdataMonth"
            :options="optionsMonth"/>
        </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row max-height="400" no-gutters>
        <v-col cols="12" sm="12">
          <chart-bar id="year"
            v-if="loadedYear"
            :chartdata="chartdataYear"
            :options="optionsYear"/>
        </v-col>
        <v-responsive
          width="100%"
        ></v-responsive>
    </v-row>
   </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import chartBar from '../components/partials/chartBar.vue';
import cardLastLog from '../components/partials/cardLastLog.vue';

export default {
  components: {
    'chart-bar': chartBar,
    'last-log': cardLastLog,
  },
  data() {
    return {
      loadedToday: false,
      chartdataToday: null,
      optionsToday: null,
      loadedMonth: false,
      chartdataMonth: null,
      optionsMonth: null,
      loadedYear: false,
      chartdataYear: null,
      optionsYear: null,

      productLog: {
        title: 'PRODUCT',
        color: '#2196f3',
        type: '',
        date: '',
        content: '',
        destinyShop: '',
      },
      inventoryLog: {
        title: 'INVENTORY',
        color: '#ff9800',
        date: '',
        content: '',
        type: '',
        destinyShop: '',
      },
      orderLog: {
        title: 'ORDER',
        color: '#f44336',
        date: '',
        content: '',
        type: '',
        destinyShop: '',
      },
      orders: [],
      dropshipper: [],
      shops: [],
      totals: [],
      bgColors: [],
    };
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    axios.defaults.headers.common.authorization = this.$store.state.token;
  },
  methods: {
    clearLog() {
      this.productLog = {
        date: '',
        content: '',
        type: '',
        destinyShop: '',
      };
      this.inventoryLog = {
        date: '',
        content: '',
        type: '',
        destinyShop: '',
      };
      this.orderLog = {
        date: '',
        content: '',
        type: '',
        destinyShop: '',
      };
    },
    getTypeLogReverse(type) {
      let result = null;
      switch (type) {
        case 0:
          result = 'Info';
          break;
        case 1:
          result = 'Warning';
          break;
        case 2:
          result = 'Error';
          break;
        default:
          result = '-';
      }
      return result;
    },
    getLastLog(title) {
      const urlConsult = `log/get/last-general/${title}`;
      axios.get(urlConsult).then(async (res) => {
        if (res.data.length > 0) {
          switch (title) {
            case 'product':
              this.productLog.date = res.data[0].date;
              this.productLog.content = res.data[0].content;
              this.productLog.type = this.getTypeLogReverse(res.data[0].type);
              this.productLog.destinyShop = res.data[0].destinyShop ? res.data[0].destinyShop : '';
              break;
            case 'inventory':
              this.inventoryLog.date = res.data[0].date;
              this.inventoryLog.content = res.data[0].content;
              this.inventoryLog.type = this.getTypeLogReverse(res.data[0].type);
              this.inventoryLog.destinyShop = res.data[0].destinyShop ? res.data[0].destinyShop : '';
              break;
            case 'order':
              this.orderLog.date = res.data[0].date;
              this.orderLog.content = res.data[0].content;
              this.orderLog.type = this.getTypeLogReverse(res.data[0].type);
              this.orderLog.destinyShop = res.data[0].destinyShop ? res.data[0].destinyShop : '';
              break;
            default:
              this.clearLog();
          }
        }
      });
    },
    listarToday() {
      const me = this;
      me.sales = [];
      this.loaded = false;
      const from = moment().format('YYYY-MM-DD');
      const to = moment().add(1, 'days').format('YYYY-MM-DD');
      const urlConsult = `myorder/get/total/orders?from=${from}&to=${to}`;
      axios.get(urlConsult).then(async (res) => {
        const data = await this.getDataChart(res.data.dropshipper, res.data.body);
        me.graphic('today', data.totals, data.shops, '#E53935', 'Orders Today');
      });
    },
    listarMonth() {
      const me = this;
      me.sales = [];
      const from = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const to = moment(from).endOf('month').format('YYYY-MM-DD');
      const month = moment(from).format('MMMM');
      const urlConsult = `myorder/get/total/orders?from=${from}&to=${to}`;
      axios.get(urlConsult).then(async (res) => {
        const data = await this.getDataChart(res.data.dropshipper, res.data.body);
        me.graphic('month', data.totals, data.shops, '#C62828', `Orders ${month}`);
      });
    },
    listarYear() {
      const me = this;
      me.sales = [];
      const from = moment().startOf('year').format('YYYY-MM-DD');
      const to = moment(from).endOf('year').format('YYYY-MM-DD');
      const year = moment(from).format('YYYY');
      const urlConsult = `myorder/get/total/orders?from=${from}&to=${to}`;
      axios.get(urlConsult).then(async (res) => {
        const data = await this.getDataChart(res.data.dropshipper, res.data.body);
        me.graphic('year', data.totals, data.shops, '#B71C1C', `Orders ${year}`);
      });
    },
    async getDataChart(dropshipper, orders) {
      const totals = [];
      const shops = [];
      const bgColors = [];
      await dropshipper.forEach(doc => {
        const totalSales = orders.filter((d) => d.shopEnd === doc._id);
        const nameShop = doc.name;
        if (totalSales.length > 1) {
          totals.push(totalSales.length);
          shops.push(nameShop);
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          bgColors.push(`#${randomColor}`);
        }
      });
      return { totals, shops, bgColors };
    },
    graphic(idChart, totals, shops, bgColors, title) {
      const docs = {
        labels: shops,
        datasets: [{
          label: title,
          data: totals,
          backgroundColor: bgColors,
          borderColor: bgColors,
          borderWidth: 1,
        }],
      };
      const opts = {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
      switch (idChart) {
        case 'today':
          this.chartdataToday = docs;
          this.loadedToday = true;
          this.optionsToday = opts;
          break;
        case 'month':
          this.chartdataMonth = docs;
          this.loadedMonth = true;
          this.optionsMonth = opts;
          break;
        case 'year':
          this.chartdataYear = docs;
          this.loadedYear = true;
          this.optionsYear = opts;
          break;
        default:
      }
    },
  },
  mounted() {
    this.listarToday();
    this.listarMonth();
    this.listarYear();
    this.getLastLog('product');
    this.getLastLog('inventory');
    this.getLastLog('order');
  },
};
</script>
