var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('notify-custom',{attrs:{"showNotify":_vm.show,"type":_vm.tipoNotify,"message":_vm.msgNotify,"icontext":_vm.iconNotify,"color":_vm.color}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalItems,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.objetos,"footer-props":_vm.propsTextFooterDataTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Variants que no se han sincronizado - Dropshipper "+_vm._s(_vm.name))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":_vm.txtMessages.txtSearch,"single-line":"","hide-details":""},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small')]),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1)]},proxy:true},{key:"item.variant",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.variant)?_c('div',[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Sincronizado ")])],1):_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" No existe en el Drsh ")])],1)])]}},{key:"item.variant.sku",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.variant)?_c('div',[_vm._v(" "+_vm._s(item.variant.sku || 'N/A')+" ")]):_c('div',[_vm._v(" "+_vm._s('N/A')+" ")])])]}},{key:"item.variant.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.variant)?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.variant.updated_at).format('YYYY-MM-DD HH:MM'))+" ")]):_c('div',[_vm._v(" "+_vm._s('N/A')+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.sincProduct(item)}}},[_vm._v(" sinc. producto ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listar()}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtReset)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }