<template>
  <v-layout align-center justify-center>
    <v-row justify="space-around" class="text-center">
      <v-col cols="12" md="6">
        <h1 class="display-2 font-weight-bold mb-3">
          {{ txtMessages.txtWelcome }}
        </h1>
        <p class="subheading font-weight-regular">
          {{ txtMessages.txtDescriptionApp }}
          <br>
        </p>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-toolbar dark color="grey darken-3">
            <v-toolbar-title>
              {{ txtMessages.txtLogIn }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field v-model="email" autofocus
            color="accent" :label="txtMessages.txtEmail" required
              :rules="[rules.email, rules.required]">
            </v-text-field>
            <!-- <v-text-field v-model="password" type="password"
                autofocus color="accent" label="Password" required>
                </v-text-field> -->
            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'" name="input-10-1"
              required :label="txtMessages.lblUserPassword"
              @click:append="show1 = !show1"></v-text-field>
            <v-flex class="red--text" v-if="errorm">
              {{ errorm }}
            </v-flex>
          </v-card-text>
          <v-card-actions class="px-3 pb-3">
            <v-flex class="text-xs-right">
              <v-btn @click="ingresar()" color="primary">{{ txtMessages.txtLogIn }}</v-btn>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn color="primary" text small>
              <router-link :to="{ name: 'Forgot' }">
                {{ txtMessages.txtForgotTitle }}
              </router-link>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import axios from 'axios';
import langMsg from '../config/generalMessages';

export default {
  data() {
    return {
      txtMessages: langMsg,
      email: '',
      password: '',
      errorm: null,
      show1: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'login-layout');
  },
  methods: {
    ingresar() {
      axios.post('/login', { email: this.email, password: this.password }).then((response) => {
        const { data } = response;
        this.$store.dispatch('saveToken', data);
        if (data.obj.rol !== 'datafeed') {
          this.$router.push({ name: 'Stats' });
        } else {
          this.$router.push({ name: 'VariantsDropshipper' });
        }
      }).catch((err) => {
        // throw err;
        this.errorm = null;
        if (err.response.status === 404 || err.response.status === 401) {
          this.errorm = 'Correo o contraseña incorrecta';
        } else {
          this.errorm = 'Server error';
        }
      });
    },
  },
};
</script>
