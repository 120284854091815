const config = require('./index');

const lang = localStorage.getItem('lang');
const language = lang || config?.config?.language;

const english = {
  suspendService: 'Suspend service dropshipping',
  txtErrorInventory: 'error inv',
  txtNeedPayShopify: 'status shopify',
  configApp: 'config App',
  generalStats: 'general stats',
  createDropshipper: 'create dropshipper',
  createDropshipperSimple: 'create drophipper simple',
  listUser: 'list user',
  listDropshipper: 'list drophipper',
  listLogsGeneral: 'list logs general',
  plnVariants: 'PLN variants',
  dropshipperVariants: 'dropshipper variants',

  downloadProducts: 'download products',
  itemsTypeLogs: ['none', 'Info', 'Warning', 'Error'],
  itemsStatusDrsh: ['active', 'inactive', 'suspend'],
  itemsTitleLogs: ['none', 'product', 'variants', 'inventory', 'status', 'order', 'cron'],
  txtSyncOrder: 'Sync order',
  txtSyncProduct: 'status products',
  txtActivateSyncInv: 'the synchronization of inventory was activated for',
  txtInactiveSyncInv: 'the synchronization of inventory was inactivated for',
  txtActivateSyncProd: 'the synchronization of product was activated for',
  txtInactiveSyncProd: 'the synchronization of product was inactivated for',
  txtActivateSyncOrder: 'the synchronization of orders was activated for',
  txtInactiveSyncOrder: 'the synchronization of orders was inactivated for',
  txtDescriptionApp: 'Central information of your wholesale stores',
  txtWelcome: 'Welcome to Catalog Connect',
  txtQtSyncOrders: 'want to sync orders??',
  txtQtSyncInventory: 'want to sync inventory??',
  txtQtSyncProducts: 'want to sync products??',
  txtInstructionsForgot: 'type the email of your account, we will send you an email',
  txtConfirmActivate: 'Are you sure you want to activate',
  txtConfirmInactivate: 'Are you sure you want to inactive',

  txtOrderNote: 'Note',
  txtOrderShippingLine: 'Shipping line',
  txtInventoryItemId: 'inventory item id',
  txtProductId: 'product id',
  txtVariantId: 'variant id',
  txtInactive: 'Inactive',
  txtActive: 'Active',
  txtCancelledDate: 'Cancelled Date',
  txtCancelledReason: 'Cancelled Reason',
  txtCancelled: 'Cancelled',
  txtFulfilled: 'Fulfilled',
  txtOrderIdProvider: 'order id Provider',
  txtOrderPriceProvider: 'order price Provider',
  txtOrderPriceDropshipper: 'order price Dropshipper',
  txtorderIdDropshipper: 'order id Dropshipper',
  txtOrderNameProvider: 'order name Provider',
  txtorderNameDropshipper: 'order name Dropshipper',
  txtOrders: 'Orders',
  txtOrdersNoSync: 'Orders no synced',
  txtLogIn: 'LOG IN',
  txtOrderNumber: 'Order number',
  txtOrderName: 'Order name',
  txtContent: 'Content',
  txtTitle: 'Title',
  txtType: 'Type',
  txtWarning: 'Warning',
  txtUserName: 'User Name',
  txtSendEmail: 'Send Email',
  txtEmail: 'Email',
  txtForgotTitle: 'Forgot your password',
  txtPermissionsObtained: 'permissions obtained',
  txtSingInShopify: 'Sing in Shopify',
  txtUsersTitle: 'Users',
  txtConfigTitle: 'Config General App',
  txtCreditCardTitle: 'Credit card details',
  txtCreateTitle: 'Create',
  txtCustomerShopify: 'Customer shopify catalog',
  txtContactInformation: 'Contact Information',
  txtBack: 'Back',
  txtContinue: 'Continue',
  txtCancel: 'Cancel',
  txtClose: 'Close',
  txtConfirm: 'Confirm',
  txtAccept: 'Accept',
  txtDetails: 'Details',
  txtProduct: 'Product',
  txtReset: 'Reset',
  txtExport: 'Export data',
  txtSearch: 'Search',
  txtSaveChanges: 'Save Changes',
  txtInactivate: 'Inactivate',
  txtActivate: 'Activate',
  txtChangeStatus: 'Change Status',
  txtCreationDate: 'creation date',
  txtUpdateDate: 'update date',
  txtContact: 'contact',
  txtShop: 'shop',
  txtAgent: 'agent',
  txtCondPrice: 'Cond. Price',
  txtAutoPayment: 'Auto Payment',
  txtToSync: 'To Sync',
  txtRecords: 'Records',
  txtOptions: 'Options',
  txtNewItem: 'New Item',
  txtSave: 'Save',
  txtEdit: 'Edit',
  txtCreatedBy: 'created by',
  txtUserType: 'user type',
  txtLastName: 'last name',
  txtName: 'name',
  txtSyncProducts: 'Sync Products',
  txtSyncInventory: 'Sync Inventory',
  txtSyncInvDisabled: 'Sync Inventory Disabled',
  txtSyncOrders: 'Sync Orders',
  txtSyncOrderDisabled: 'Sync Orders Disabled',
  txtGoToLogList: 'go to Log list',
  txtGoToOrdersList: 'go to Orders Synced list',
  txtGoToOrdersListNoSync: 'go to Orders no Synced list',

  lblSelectTitleLog: 'Select title log',
  lblSelectTypeLog: 'Select type log',
  lblSelectStatus: 'Select status',
  lblSelectTypeUser: 'Select type user',
  lblSelectRol: 'Select rol',
  lblNickName: 'Nick Name',
  lblLastName: 'Last Name',
  lblUserPassword: 'User Password',
  lblZipCode: 'zip code',
  lblExpirationDate: 'expiration date',
  lblNumberCreditCard: 'number credit card',
  lblUrlShop: 'url shop',
  lblTagProduct: 'shopify tag product',
  lblTypeSyncInv: 'Select type sync inventory',
  lblMailAuditInventory: 'mail audit inventory',
  lblStatusAuditInv: 'Status audit inventory',
  lblCompanyName: 'Company Name',
  lblContactEmail: 'Contact Email',
  lblContactName: 'Contact Name',
  lblContactPhone: 'Contact Phone',
  lblCustomerEmail: 'email customer in catalog',
  lblSelectAgent: 'Select agent',
};

const spanish = {
  suspendService: 'Suspender servicio completo',
  txtErrorInventory: 'error inv',
  txtNeedPayShopify: 'status shopify',
  configApp: 'configuracion App',
  generalStats: 'estadisticas generales',
  createDropshipper: 'crear dropshipper',
  createDropshipperSimple: 'crear drophipper simple',
  listUser: 'lista de usuarios',
  listDropshipper: 'lista de drophippers',
  listLogsGeneral: 'lista de logs general',
  plnVariants: 'PLN variantes',
  dropshipperVariants: 'dropshipper variantes',
  downloadProducts: 'descargar productos',
  itemsTypeLogs: ['none', 'Info', 'Warning', 'Error'],
  itemsStatusDrsh: ['active', 'inactive', 'suspend'],
  itemsTitleLogs: ['none', 'product', 'variants', 'inventory', 'status', 'order', 'cron'],
  txtSyncOrder: 'Sinc order',
  txtSyncProduct: 'status productos',
  txtActivateSyncInv: ' La sincronizacion de inventario fue activada para',
  txtInactiveSyncInv: 'La sincronizacion de inventario fue desactivada para',
  txtActivateSyncOrder: 'La sincronizacion de ordenes fue activada para',
  txtInactiveSyncOrder: 'La sincronizaciond de ordenes fue desactivada para',
  txtDescriptionApp: 'Informacion de tus clientes Dropshipping',
  txtWelcome: 'Bienvenido a Catalog Connect',
  txtQtSyncOrders: 'Desea sincronizar ordenes??',
  txtQtSyncInventory: 'Desea sincronizar inventario??',
  txtQtSyncProducts: 'Desea sincronizar productos??',
  txtInstructionsForgot: 'Escribe el correo de tu cuenta en catalog connect, te enviaremos un correo de recuperacion',
  txtConfirmActivate: 'Estas seguro de activar',
  txtConfirmInactivate: 'Estas seguro de inactivar',

  txtOrderNote: 'Note',
  txtOrderShippingLine: 'Shipping line',
  txtInventoryItemId: 'inventario item id',
  txtProductId: 'producto id',
  txtVariantId: 'variante id',
  txtInactive: 'Inactivo',
  txtActive: 'Activo',
  txtCancelledDate: 'Fecha de cancelacion',
  txtCancelledReason: 'Motivo de cancelacion',
  txtCancelled: 'Cancelado',
  txtFulfilled: 'Completado',
  txtOrderIdProvider: 'orden id Proveedor',
  txtOrderPriceProvider: 'orden precio Proveedor',
  txtOrderPriceDropshipper: 'orden precio Dropshipper',
  txtorderIdDropshipper: 'orden id Dropshipper',
  txtOrderNameProvider: 'orden name Proveedor',
  txtorderNameDropshipper: 'orden name Dropshipper',
  txtOrders: 'Ordenes',
  txtOrdersNoSync: 'Ordenes no sincronizadas',
  txtLogIn: 'Ingresar',
  txtOrderNumber: 'Numero de orden',
  txtOrderName: 'Nombre de orden',
  txtContent: 'Contenido',
  txtTitle: 'Titulo',
  txtType: 'Tipo',
  txtWarning: 'Warning',
  txtUserName: 'Nombre de usuario',
  txtSendEmail: 'Enviar email',
  txtEmail: 'Email',
  txtForgotTitle: 'Forgot your password',
  txtPermissionsObtained: 'permisos obtenidos',
  txtSingInShopify: 'Ingresar en Shopify',
  txtUsersTitle: 'Usuarios',
  txtConfigTitle: 'Configuracion General de la app',
  txtCreditCardTitle: 'detalles de tarjeta de credito',
  txtCreateTitle: 'Crear',
  txtCustomerShopify: 'Cliente en Shopify Catalog',
  txtContactInformation: 'Informacion de contacto',
  txtBack: 'Regresar',
  txtContinue: 'Continuar',
  txtCancel: 'Cancelar',
  txtClose: 'Cerrar',
  txtConfirm: 'Confirmar',
  txtAccept: 'Aceptar',
  txtDetails: 'Detalles',
  txtProduct: 'Productos',
  txtReset: 'Reiniciar',
  txtExport: 'Exportar datos',
  txtSearch: 'Buscar',
  txtSaveChanges: 'Guardar Cambios',
  txtInactivate: 'Inactivar',
  txtActivate: 'Activar',
  txtChangeStatus: 'Cambiar Status',
  txtCreationDate: 'fecha de creacion',
  txtUpdateDate: 'fecha de edicion',
  txtContact: 'contacto',
  txtShop: 'shop',
  txtAgent: 'agent',
  txtCondPrice: 'Cond. Precio',
  txtAutoPayment: 'Auto Pago',
  txtToSync: 'Para Sincronizar',
  txtRecords: 'Registros',
  txtOptions: 'Opciones',
  txtNewItem: 'Nuevo item',
  txtSave: 'Guardar',
  txtEdit: 'Editar',
  txtCreatedBy: 'creado por',
  txtUserType: 'tipo de usario',
  txtLastName: 'apellido',
  txtName: 'nombre',
  txtSyncProducts: 'Sinc Productos',
  txtSyncInventory: 'Sinc Inventario',
  txtSyncInvDisabled: 'Sinc Inventaro Inactivo',
  txtSyncOrders: 'Sinc Ordenes',
  txtSyncOrderDisabled: 'Sinc Ordenes Inactivo',
  txtGoToLogList: 'ir a lista de Logs',
  txtGoToOrdersList: 'ir a ordenes sincronizadas',
  txtGoToOrdersListNoSync: 'ir a ordenes no sincronizadas',
  txtGoToOrdersListProducts: 'ir a lista de Productos',
  lblSelectTitleLog: 'Selecciona titulo de log',
  lblSelectTypeLog: 'Selecciona tipo de log',
  lblSelectStatus: 'Selecciona status',
  lblSelectTypeUser: 'Selecciona tipo de usuario',
  lblSelectRol: 'Selecciona rol',
  lblNickName: 'Apodo',
  lblLastName: 'Apellido',
  lblUserPassword: 'Contraseña de usuario',
  lblZipCode: 'codigo postal',
  lblExpirationDate: 'fecha de expiracion',
  lblNumberCreditCard: 'numero de tarjeta de credito',
  lblUrlShop: 'enlace de la tienda cliente',
  lblTagProduct: 'shopify tag producto',
  lblTypeSyncInv: 'Selecciona tipo de sincronizacion de inventario',
  lblMailAuditInventory: 'email de auditoria de inventario',
  lblStatusAuditInv: 'Status de auditoria de inventario',
  lblCompanyName: 'Nombre de Compañia',
  lblContactEmail: 'Email de contacto',
  lblContactName: 'Nombre de contacto',
  lblContactPhone: 'Telefono de contacto',
  lblCustomerEmail: 'email de cliente en catalog',
  lblSelectAgent: 'Selecciona agente',
};

module.exports = language === 'english' ? english : spanish;
