export default (to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (to.matched.some(record => record.meta.free)) {
    next();
  } else if (user) {
    switch (user.rol) {
      case 'admin':
        // code block
        if (to.matched.some(record => record?.meta?.admin)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'developer':
        if (to.matched.some(record => record?.meta?.developer)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'dev':
        if (to?.matched?.some(record => record?.meta?.dev)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'comercial':
        if (to.matched.some(record => record?.meta?.comercial)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'support':
        if (to.matched.some(record => record?.meta?.support)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'pln':
        // code block
        if (to.matched.some(record => record?.meta?.pln)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      case 'datafeed':
        // code block
        if (to.matched.some(record => record?.meta?.datafeed)) {
          next();
        } else {
          next({ name: 'forbidden' });
        }
        break;
      default:
        // code block
        next({ name: 'forbidden' });
    }
  } else {
    next({ name: 'Login' });
  }
};
